import React from 'react';
import styles from './BottomNavigation.module.css';
import { BsChatDots, BsJournalText } from 'react-icons/bs';

function BottomNavigation({ activeView, setActiveView }) {
  return (
    <div className={styles.bottomNav}>
      <button
        className={`${styles.navButton} ${activeView === 'chat' ? styles.active : ''}`}
        onClick={() => setActiveView('chat')}
      >
        <BsChatDots className={styles.icon} />
        <span>Chat</span>
      </button>
      <button
        className={`${styles.navButton} ${activeView === 'notes' ? styles.active : ''}`}
        onClick={() => setActiveView('notes')}
      >
        <BsJournalText className={styles.icon} />
        <span>Notes</span>
      </button>
    </div>
  );
}

export default BottomNavigation;