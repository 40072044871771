'use client';
import { useState, useEffect, useCallback, useRef } from 'react';
import React from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import styles from './ChatComponent.module.css';
import Document from './Document';
import { BsPlusCircle, BsFillPlusCircleFill } from "react-icons/bs";

const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://jamgpt.ai' 
  : 'http://localhost:3001';

export default function ChatComponent({ username, socket, roomId, documentContent, setDocumentContent }) {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [userColors, setUserColors] = useState({});
  const [hoveredMessageIndex, setHoveredMessageIndex] = useState(null);
  const [typingUsers, setTypingUsers] = useState([]);
  const typingTimeoutRef = useRef(null);

  const messageListRef = useRef(null);

  useEffect(() => {
    if (socket) {
      socket.on('message', (message) => {
        setChatHistory(prevHistory => [...prevHistory, message]);
      });

      socket.on('documentUpdate', (content) => {
        setDocumentContent(content);
      });

      socket.on('processingStatus', (status) => {
        setIsProcessing(status);
      });

      socket.on('userTyping', ({ user }) => {
        setTypingUsers(prev => [...new Set([...prev, user])]);
      });

      socket.on('userStoppedTyping', ({ user }) => {
        setTypingUsers(prev => prev.filter(u => u !== user));
      });

      // New effect to scroll to bottom when chat history updates
      if (messageListRef.current) {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }

      return () => {
        socket.off('message');
        socket.off('documentUpdate');
        socket.off('processingStatus');
        socket.off('userTyping');
        socket.off('userStoppedTyping');
      };
    }
  }, [socket, chatHistory]); // Add chatHistory to the dependency array

  const sendMessage = async (e) => {
    e.preventDefault();
    if (message.trim() && !isProcessing) {
      const newMessage = { user: username, text: message };
      socket.emit('message', { message: newMessage, roomId });
      setMessage('');
      setIsProcessing(true);

      try {
        socket.emit('processingStatus', { status: true, roomId });
        const response = await axios.post(`${API_URL}/api/chat`, { message, username });
        const aiMessage = { user: 'AI', text: response.data.reply };
        socket.emit('message', { message: aiMessage, roomId });
      } catch (error) {
        console.error('Error sending message:', error);
        const errorMessage = { user: 'System', text: 'Error: Failed to get AI response' };
        socket.emit('message', { message: errorMessage, roomId });
      } finally {
        setIsProcessing(false);
        socket.emit('processingStatus', { status: false, roomId });
      }
    }
  };

  const handleAddToDocument = useCallback((text) => {
    const updatedContent = documentContent + (documentContent ? '\n\n' : '') + text;
    setDocumentContent(updatedContent);
    socket.emit('documentUpdate', { content: updatedContent, roomId });
  }, [documentContent, socket, roomId]);

  const renderMessageText = (text) => {
    if (text.includes('```') || text.includes('**') || text.includes('*') || text.includes('##')) {
      return <ReactMarkdown>{text}</ReactMarkdown>;
    }
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default to avoid adding a new line
      sendMessage(e);
    }
  };

  const emitTyping = () => {
    socket.emit('typing', { user: username, roomId });
    
    clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      socket.emit('stoppedTyping', { user: username, roomId });
    }, 1000);
  };

  return (
    <div className={styles.container}>
      <div className={styles.chatContainer}>
        <div className={styles.messageList} ref={messageListRef}>
          {chatHistory.map((message, index) => (
            <div 
              key={index} 
              className={`${styles.message} ${message.user === 'AI' ? styles.aiMessage : styles.userMessage}`}
              >
              <div className={styles.messageContent}>
              {message.user !== 'AI' && <strong>{message.user}: </strong>}
                {renderMessageText(message.text)}
              </div>
              {message.user === 'AI' && (
                <button 
                  className={styles.addToDocumentButton}
                  onClick={() => handleAddToDocument(message.text)}
                  onMouseEnter={() => setHoveredMessageIndex(index)}
                  onMouseLeave={() => setHoveredMessageIndex(null)}
                  title="Add to Document"
                >
                  {hoveredMessageIndex === index ? <BsFillPlusCircleFill /> : <BsPlusCircle />}
                </button>
              )}
            </div>
          ))}
          {isProcessing && (
            <div className={styles.loadingIndicator}>
              <div className={styles.loadingDots}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          )}
          {typingUsers.map(user => (
            <div key={user} className={styles.typingIndicator}>
              {user} is typing...
            </div>
          ))}
        </div>
        <form onSubmit={sendMessage} className={styles.inputForm}>
          <textarea
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              emitTyping();
            }}
            onKeyDown={handleKeyDown}
            placeholder="Type your message... "
            className={styles.input}
            disabled={isProcessing}
            rows="2"
          />
          <button 
            type="submit" 
            className={styles.button}
            disabled={isProcessing}
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
}