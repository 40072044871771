import { useState } from 'react';
import styles from './ShareModal.module.css';

function ShareModal({ roomUrl, onClose }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(roomUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h2>Share</h2>
        <p>Invite other people to this room</p>
        <input type="text" value={roomUrl} readOnly className={styles.urlInput} />
        <button onClick={copyToClipboard} className={styles.copyButton}>
          {copied ? 'Copied!' : 'Copy URL'}
        </button>
        <button onClick={onClose} className={styles.closeButton}>Close</button>
      </div>
    </div>
  );
}

export default ShareModal;