import { useEffect, useRef } from 'react';
import styles from './Document.module.css';

export default function Document({ content, setContent, socket, roomId }) {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (socket) {
      socket.on('documentUpdate', (updatedContent) => {
        setContent(updatedContent);
      });

      // Request initial document content when joining the room
      socket.emit('getDocumentContent', roomId);

      return () => {
        socket.off('documentUpdate');
      };
    }
  }, [socket, setContent, roomId]);

  const handleContentChange = (e) => {
    const newContent = e.target.value;
    setContent(newContent);
    socket.emit('documentUpdate', { content: newContent, roomId });
  };

  return (
    <div className={styles.documentContainer}>
      <div className={styles.notepadBackground}>
        <textarea
          ref={textareaRef}
          className={styles.textArea}
          value={content}
          onChange={handleContentChange}
          placeholder="Start typing notes..."
        />
      </div>
    </div>
  );
}