import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './Home.module.css';

function Home() {
  const [creating, setCreating] = useState(false);
  const navigate = useNavigate();

  const createRoom = async () => {
    setCreating(true);
    try {
      const response = await axios.post('/api/create-room');
      navigate(`/id/${response.data.roomId}`);
    } catch (error) {
      console.error('Error creating room:', error);
      setCreating(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>JamGPT</h1>
      <div className={styles.features}>
        <div className={styles.feature}>
          <span className={styles.emoji}>💡</span>
          <p>Jam on ideas with AI</p>
        </div>
        <div className={styles.feature}>
          <span className={styles.emoji}>👥</span>
          <p>Invite other people to jam with you</p>
        </div>
        <div className={styles.feature}>
          <span className={styles.emoji}>📝</span>
          <p>Save the best ideas on your notepad</p>
        </div>
      </div>
      <button 
        className={styles.button} 
        onClick={createRoom} 
        disabled={creating}
      >
        {creating ? 'Creating...' : 'Start'}
      </button>
    </div>
  );
}

export default Home;