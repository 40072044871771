import React, { useState } from 'react';
import styles from './Header.module.css';
import Modal from './Modal';

function Header({ users, username, onShare, onLeave }) {
  const [showModal, setShowModal] = useState(false);

  const handleUsernameClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <header className={styles.header}>
      <div className={styles.logo}></div>
      <div className={styles.users}>
        {users.map((user, index) => (
          <span key={index} className={styles.user}>{user}</span>
        ))}
      </div>
      <div className={styles.userInfo}>
        <span className={styles.username} onClick={handleUsernameClick}>{username}</span>
        <button className={styles.shareButton} onClick={onShare}>Share</button>
      </div>
      {showModal && (
        <Modal onClose={handleCloseModal}>
          <p>Logged in as {username}</p>
          <button className={styles.leaveButton} onClick={onLeave}>Leave Room</button>
        </Modal>
      )}
    </header>
  );
}

export default Header;