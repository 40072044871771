import React, { useState } from 'react';
import styles from './UsernameModal.module.css';

const UsernameModal = ({ onSubmit }) => {
  const [username, setUsername] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username.trim()) {
      onSubmit(username);
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        {/* <p>Please enter a name to get started</p> */}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter a name"
            className={styles.input}
          />
          <button type="submit" className={styles.button}>
            Join Chat
          </button>
          <br/>
          <i>Chat last for one day</i>
        </form>
      </div>
    </div>
  );
};

export default UsernameModal;