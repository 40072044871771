import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import Header from './Header';
import ChatComponent from './ChatComponent';
import UsernameModal from './UsernameModal';
import Document from './Document';
import ShareModal from './ShareModal';
import BottomNavigation from './BottomNavigation';
import styles from './Room.module.css';

const SOCKET_URL = process.env.NODE_ENV === 'production' 
  ? 'https://jamgpt.ai' 
  : 'http://localhost:3001';

function Room() {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState('');
  const [socket, setSocket] = useState(null);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [documentContent, setDocumentContent] = useState('');
  const [activeView, setActiveView] = useState('chat');
  const { roomId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const newSocket = io(SOCKET_URL);
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    } else {
      setShowUsernameModal(true);
    }
  }, []);

  useEffect(() => {
    if (!socket || !username || !roomId) return;

    socket.emit('join', { username, roomId });

    socket.on('updateUsers', (updatedUsers) => {
      setUsers(updatedUsers);
    });

    return () => {
      socket.off('updateUsers');
    };
  }, [socket, username, roomId]);

  const handleUsernameSubmit = (newUsername) => {
    setUsername(newUsername);
    localStorage.setItem('username', newUsername);
    setShowUsernameModal(false);
  };

  const handleShare = () => {
    setShowShareModal(true);
  };

  const handleLeave = () => {
    localStorage.removeItem('username');
    navigate('/');
  };

  const handleAddToDocument = (text) => {
    const updatedContent = documentContent + (documentContent ? '\n\n' : '') + text;
    setDocumentContent(updatedContent);
    socket.emit('documentUpdate', { content: updatedContent, roomId });
  };

  if (showUsernameModal) {
    return <UsernameModal onSubmit={handleUsernameSubmit} />;
  }

  return (
    <div className={styles.roomContainer}>
      <Header 
        users={users} 
        username={username} 
        onShare={handleShare} 
        onLeave={handleLeave}
      />
      <div className={styles.contentContainer}>
        <div className={`${styles.view} ${styles.chatView} ${activeView === 'chat' ? styles.active : ''}`}>
          <ChatComponent 
            username={username} 
            socket={socket} 
            roomId={roomId} 
            onAddToDocument={handleAddToDocument}
            documentContent={documentContent}
            setDocumentContent={setDocumentContent}
          />
        </div>
        <div className={`${styles.view} ${styles.documentView} ${activeView === 'notes' ? styles.active : ''}`}>
          <Document 
            content={documentContent} 
            setContent={setDocumentContent} 
            socket={socket} 
            roomId={roomId} 
          />
        </div>
      </div>
      <BottomNavigation activeView={activeView} setActiveView={setActiveView} />
      {showShareModal && (
        <ShareModal
          roomUrl={window.location.href}
          onClose={() => setShowShareModal(false)}
        />
      )}
    </div>
  );
}

export default Room;